import { ImageContext } from "../context";
import useImage from "../hooks/useImage";

const ImageContextProvider = (props) => {
  // eslint-disable-next-line no-unused-vars

  const imagesAll = useImage("photos/1");
  console.log(imagesAll);

  return (
    <ImageContext.Provider value={imagesAll}>
      {props.children}
    </ImageContext.Provider>
  );
};

export default ImageContextProvider;
