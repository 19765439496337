import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from ".";

function useLanguage() {
  const { storedValue: localLang } = useLocalStorage("lang", "tr");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log(localLang, "localLang");

    if (localLang) {
      i18n.changeLanguage(localLang);
      console.log("lang is changed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localLang, i18n]);
  return { t, i18n };
}

export default useLanguage;
