import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./helpers/i18-init";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { ScrollToTopOnRouteChange } from "./helpers";
import ImageContextProvider from "./providers";
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const About = React.lazy(() => import("./pages/About"));
const Technology = React.lazy(() => import("./pages/Technology"));
const SuperApp = React.lazy(() => import("./pages/SuperApp"));
const PrivacyPolicies = React.lazy(() => import("./pages/PrivacyPolicies"));

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ImageContextProvider>
          <ScrollToTopOnRouteChange>
            <Suspense fallback={<div style={{ minHeight: "100vh" }} />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/hakkimizda" element={<About />} />
                <Route path="/neden-super-uygulama" element={<SuperApp />} />
                <Route path="/teknoloji" element={<Technology />} />
                <Route path="/gizlilik-metni" element={<PrivacyPolicies />} />
                <Route path="/" />
              </Routes>
            </Suspense>
            <Footer />
          </ScrollToTopOnRouteChange>
        </ImageContextProvider>
      </Router>
    </>
  );
}

export default App;
