import {
  Button,
  ButtonProps,
  Container,
  OutlinedInput,
  styled,
  Box,
} from "@mui/material";
export const MainRoot = styled(Container)(({ theme }) => ({
  margin: "0",
  padding: "0",
  maxWidth: "100%",
  minWidth: "100%",
  backgroundColor: "#EEEE",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "0",
    paddingRight: "0",
    maxWidth: "100%",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "0",
    paddingRight: "0",
    maxWidth: "100%",
  },
}));
export const Root = styled(Container)(({ theme }) => ({
  margin: "0",
  paddingTop: "5rem",
  paddingBottom: "5rem",
  maxWidth: "100%",
  minWidth: "100%",
  backgroundColor: "#EEEEEE",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    maxWidth: "100%",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    maxWidth: "100%",
    paddingTop: "5rem",
    paddingBottom: "5rem",
  },
}));
export const Item = styled(Box)(({ theme }) => ({
  margin: "0",
  padding: "0",
  maxWidth: "50vw",
  [theme.breakpoints.down("md")]: {
    padding: "0",
    maxWidth: "90vw",
  },
}));
export const Logo = styled(`img`)(({ theme }) => ({
  width: "19.5rem",
  height: "auto",
  marginBottom: "3.06rem",
  [theme.breakpoints.down("md")]: {
    width: "11.375rem",
    marginBottom: "2rem",
  },
}));
export const FooterTitle = styled(`h2`)(({ theme }) => ({
  margin: "0",
  padding: "0",
  fontFamily: "HelveticaNeueLT Pro Lt",
  fontSize: "2.875rem",
  fontWeight: "bold",
  lineHeight: "3.5rem",
  [theme.breakpoints.down("md")]: {
    display: "block",
    fontSize: "2.25rem",
    lineHeight: "2.75rem",
  },
}));
export const FooterAdress = styled(`p`)(({ theme }) => ({
  margin: "0",
  padding: "0",
  paddingRight: "6rem",
  marginBottom: "9.06rem",
  marginTop: "1.5rem",
  fontFamily: "HelveticaNeueLT Pro Lt",
  fontSize: "1.125rem",
  fontWeight: "lighter",
  lineHeight: "1.375rem",
  [theme.breakpoints.down("md")]: {
    display: "inline-block",
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
    fontSize: "0.875rem",
    lineHeight: "1.06rem",
    paddingRight: "4rem",
  },
  [theme.breakpoints.down("lg")]: {
    display: "inline-block",
    paddingRight: "4rem",
  },
  [theme.breakpoints.down("xl")]: {
    display: "inline-block",
    paddingRight: "0.5rem",
  },
}));

export const SocialMediaIcon = styled(`img`)(({ theme }) => ({
  width: "4.25rem",
  height: "auto",
  marginBottom: "4rem",
  [theme.breakpoints.down("md")]: {
    width: "2.875rem",
    marginBottom: "2rem",
  },
}));
export const FormCntainer = styled(Container)(({ theme }) => ({
  maxWidth: "50vw",
  [theme.breakpoints.down("md")]: {
    margin: "0",
    paddingTop: "0",
    paddingLeft: "0",
    maxWidth: "90vw",
  },
  [theme.breakpoints.up("md")]: {
    margin: "0",
    paddingTop: "2.68rem",
    paddingLeft: "0",
  },
}));
export const FormTitle = styled(`div`)(({ theme }) => ({
  margin: "0",
  padding: "0",
  paddingBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    margin: "0",
    paddingTop: "0",
    paddingLeft: "0",
    fontFamily: "HelveticaNeueLT Pro Lt",
    fontSize: "1.125rem",
    fontWeight: "lighter",
    lineHeight: "1.375rem",
    paddingBottom: "1.25rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "0",
    paddingTop: "0",
    paddingLeft: "0",
  },
}));

export const Input = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: "72",
  display: "block",
  backgroundColor: "#fff",
  maxWidth: "320px",
  marginTop:"17px",
  [theme.breakpoints.up("md")]: {
    maxWidth: "380px",
  },
}));

export const FooterBottom = styled(Container)(({ theme }) => ({
  margin: "0",
  paddingRight: "17.5rem",
  paddingLeft: "17.5rem",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  maxWidth: "100%",
  backgroundColor: "#3E2776",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
  color: "#fff",
  fontSize: "1.125rem",
  fontWeight: "light",
  lineHeight: "1.375rem",

  [theme.breakpoints.down("md")]: {
    paddingRight: "0rem",
    paddingLeft: "0rem",
    flexDirection: "column",
    paddingTop: "1.5rem",
    paddingBottom: "2.5rem",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "100%",
    paddingRight: "17.5rem",
    paddingLeft: "17.5rem",
  },
}));
export const FooterBottomItem = styled(`div`)(({ theme }) => ({
  margin: "0",
  padding: 0,
  [theme.breakpoints.down("md")]: {
    paddingRight: "0",
    paddingLeft: "0",
    paddingBottom: "1.5rem",
  },
}));

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  height: "68px",
  borderRadius: "9px",
  fontSize: "1.125rem",
  fontFamily: "HelveticaNeueLT Pro Lt",
  fontWeight: "bolder",
  maxWidth: "432px",
  backgroundColor: "#855BFF",
  "&:hover": {
    backgroundColor: "#3E2776",
  },
}));
