import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetData } from ".";
import { API_BASE_URL } from "../constants/api";

function useServerLanguage(page) {
  const { getData } = useGetData();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    const getLanguage = async () => {
      try {
        const languageData = await getData(
          `${API_BASE_URL}/${i18n.language}/${page}/`
        );
        setLanguage(
          (languageData?.results?.length && languageData?.results[0]) ||
            languageData ||
            null
        );
      } catch (err) {
        console.log(err);
      }
    };
    getLanguage();
  }, [getData, i18n.language, page]);
  return language;
}

export default useServerLanguage;
