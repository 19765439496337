import { Typography } from "@mui/material";
import React from "react";
import "./style.css";

const defaultStyle = {
  fontSize: { xs: "1.5rem", md: "2rem" },
  lineHeight: "1.17",
};

const transformVariant = (variant) => {
  switch (variant) {
    case "text":
    case "largeText":
    case "smallText": {
      return undefined;
    }
    default:
      return variant;
  }
};

function CustomTypography(props) {
  const {
    variant,
    color,
    letterSpacing,
    className,
    fontFamily,
    children,
    ...rest
  } = props;

  const getStyleByVariant = {
    p: {
      fontWeight: "700",
    },
    h5: {
      fontSize: { xs: "1.125rem", md: "1.5rem" },
    },
    text: {
      fontSize: { xs: "1.125rem", md: "1.5rem" },
    },
    text2: {
      fontSize: { xs: "2.25rem", md: "2.875rem" },
      fontWeight: "300",
    },
    text3: {
      fontSize: { xs: "1.125rem", md: "2.25rem" },
      fontWeight: "700",
    },
    largeText: {
      fontSize: { xs: "1.5rem", md: "2rem" },
    },
    largeText2: {
      fontSize: { xs: "1.5rem", md: "2.25rem" },
    },
    smallText: {
      fontSize: { xs: "0.875rem", md: "1.125rem" },
    },
    formText: {
      fontSize: { xs: "0.6875rem", md: "0.875rem" },
    },
    informal: {
      color: "black",
      fontSize: "1rem",
    },
  };

  if (variant === "spanBold") {
    return (
      <span className={`span-bold ${className}`} {...rest}>
        {children}
      </span>
    );
  }

  return (
    <Typography
      variant={transformVariant(variant)}
      color={color || "white"}
      fontFamily={"HelveticaNeueLT Pro Lt" || fontFamily}
      letterSpacing={letterSpacing || 0}
      className={className}
      {...defaultStyle}
      {...getStyleByVariant[variant]}
      {...rest}
    >
      {children}
    </Typography>
  );
}

export default CustomTypography;
