import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetData } from ".";
import { API_BASE_URL } from "../constants/api";

function useImage(page) {
  const { getData } = useGetData();
  const { i18n } = useTranslation();
  const [image, setImage] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      try {
        const imageData = await getData(
          `${API_BASE_URL}/${i18n.language}/${page}/`
        );
        setImage(
          (imageData?.results?.length && imageData?.results[0]) ||
            imageData ||
            null
        );
      } catch (err) {
        console.log(err);
      }
    };
    getImage();
  }, [getData, i18n.language, page]);
  return image;
}

export default useImage;
