import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const defaultState = {
  hits: null,
  error: null,
  isLoading: false,
};

let request = null;

function useGetData(defaultUrl, auto = false, options = {}) {
  const [data, setData] = useState(defaultState);

  const getData = useCallback(
    async (url = defaultUrl) => {
      request = axios.CancelToken.source();
      setData({ ...defaultState, isLoading: true });
      return axios(
        {
          method: "GET",
          url,
        },
        {
          cancelToken: request.token,
        }
      )
        .then((resp) => {
          setData((prevData) => ({
            ...prevData,
            isLoading: false,
            hits: resp,
          }));
          return resp.data;
        })
        .catch((error) => {
          setData((prevData) => ({ ...prevData, isLoading: false, error }));
          if (!auto) throw error;
        });
    },
    [defaultUrl, auto]
  );

  useEffect(() => {
    if (auto) getData();
    return () => request?.cancel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auto]);

  return { getData, data };
}

export default useGetData;
