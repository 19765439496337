import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from ".";

const useNavbarLogic = () => {
  const { storedValue: localLang, setValue } = useLocalStorage("lang", "tr");
  const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    const { value } = event.target;
    i18n.changeLanguage(value);
    setValue(value);
  };

  useEffect(() => {
    console.log(localLang, "localLang");

    if (localLang) {
      i18n.changeLanguage(localLang);
      console.log("lang is changed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localLang, i18n]);

  return {
    language: i18n.language,
    handleChange,
    t,
  };
};

export default useNavbarLogic;
