import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React, { useState } from "react";
import NavbarIcon from "../../assets/NavbarIcon.svg";
import LinkedinIcon from "../../assets/LinkedinIcon.svg";
import "./styles";
import "../../styles/shared.css";
import {
  MainRoot,
  FooterAdress,
  FooterTitle,
  Item,
  Root,
  Logo,
  FooterBottom,
  FooterBottomItem,
  Input,
  FormCntainer,
  ColorButton,
} from "./styles";
import CustomTypography from "../shared/CustomTypography";
import useLanguage from "../../hooks/useLanguage";
import CustomButton from "../shared/CustomButton";
import ClarificationText from "../../containers/ClarificationText";
import CustomLink from "../shared/CustomLink";
import { ROUTES } from "../../constants/routes";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box } from "@mui/system";
import axios from "axios";
import useServerLanguage from "../../hooks/useServerLanguage";
import { useNavbarLogic } from "../../hooks";

interface CustomErrorProps {
  children: React.ReactElement | string;
  styles?: object;
}

function CustomError({ children, styles }: CustomErrorProps) {
  return (
    <Box marginTop="4px" {...styles}>
      <CustomTypography fontSize="0.8rem" color="red">
        {children}
      </CustomTypography>
    </Box>
  );
}

function Footer() {
  const { i18n } = useLanguage();
  const {t} = useNavbarLogic();
  const [showClarificationText, setShowClarificationText] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const footer: any = useServerLanguage("footer");

  const validationSchema = Yup.object({
    nameSurname: Yup.string().required(
      i18n.language === "tr"
        ? "Bu alan boş bırakılamaz."
        : "This field cannot be left blank."
    ),
    email: Yup.string()
      .required(
        i18n.language === "tr"
          ? "Bu alan boş bırakılamaz."
          : "This field cannot be left blank."
      )
      .email("Lütfen geçerli bir e-posta adresi giriniz"),
    checked1: Yup.bool().oneOf(
      [true],
      i18n.language === "tr"
        ? "Sözleşmeyi kabul etmelisiniz"
        : "You must accept the contract"
    ),
    checked2: Yup.bool().oneOf(
      [true],
      i18n.language === "tr"
        ? "Sözleşmeyi kabul etmelisiniz"
        : "You must accept the contract"
    ),
  });

  const toggleClarificationText = (e: React.KeyboardEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setShowClarificationText(!showClarificationText);
  };
  /* test */
  const handleSubmit = (values: any) => {
    console.log(values);
    const url = `https://testapi.platformistanbul.westerops.com/${i18n.language}/contact/`;
    const data = {
      name: values.nameSurname,
      address: "",
      mail: values.email,
    };

    setIsFormSubmitted(false);

    axios
      .post(url, data)
      .then(() => setIsFormSubmitted(true))
      .catch((err) => console.log(err));
  };

  const PersonalDataText = (
    <CustomTypography color="black" variant="formText">
      {footer?.form_permission_text || ""}
      <CustomButton variant="textButton" onClick={toggleClarificationText}>
        {footer?.form_permission_underline_text || ""}
      </CustomButton>
    </CustomTypography>
  );
  const WarningPermission = (
    <CustomTypography color="black" variant="formText">
      {footer?.form_permission_two_text || ""}
      <CustomButton variant="textButton" onClick={toggleClarificationText}>
        {footer?.form_permission_two_underline_text || ""}
      </CustomButton>
    </CustomTypography>
  );

  return (
    <>
      <footer id="footer">
        <MainRoot>
          <Grid container className="section-max-width content-padding">
            <Root>
              <Item width="100%">
                <Logo src={NavbarIcon} />
                <FooterTitle>{footer?.title || ""}</FooterTitle>
                <FooterAdress>
                  {footer?.address_line_one || ""}
                  <br />
                  {footer?.address_line_two || ""}
                  <br />
                  {footer?.address_line_three || ""}
                  <br />
                  <br />
                  {t("Footer.email" || "")}
                </FooterAdress>
                <a
                  href="https://tr.linkedin.com/company/platformi%CC%87stanbul"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {" "}
                  <img src={LinkedinIcon} alt="LinkedinIcon" />{" "}
                </a>
              </Item>
              <Formik
                initialValues={{
                  nameSurname: "",
                  email: "",
                  checked1: false,
                  checked2: false,
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ values, handleChange, errors, touched, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormCntainer>
                      <CustomTypography variant="formText" color="black">
                        {footer?.form_title || ""}
                      </CustomTypography>
                      <Input
                        name="nameSurname"
                        placeholder={footer?.form_input_placeholder_one || ""}
                        value={values.nameSurname}
                        onChange={handleChange}
                      />
                      {errors.nameSurname && touched.nameSurname && (
                        <CustomError>{errors.nameSurname}</CustomError>
                      )}
                      <Input
                        name="email"
                        sx={{ marginTop: "1.5rem" }}
                        placeholder={footer?.form_input_placeholder_two || ""}
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <CustomError>{errors.email}</CustomError>
                      )}
                      <FormGroup
                        style={{
                          marginTop: "1.5rem",
                          display: "flex",
                          justifyContent: "row",
                        }}
                        sx={{ width: { sx: "327px", md: "400px" } }}
                      >
                        <FormControlLabel
                          labelPlacement="end"
                          sx={{
                            "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                              {
                                top: -22,
                              },
                            "& .css-i4bv87-MuiSvgIcon-root": {
                              fontSize: "2rem",
                              fill: "#92959B",
                            },

                            "& .MuiFormControlLabel-label": {
                              fontSize: "1.14rem",
                              fontWeight: "light",
                              color: "#000",
                              lineHeight: "1.06rem",
                            },
                            "@media screen and (max-width:500px)": {
                              "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                                {
                                  top: -12,
                                },
                              "& .css-i4bv87-MuiSvgIcon-root": {
                                fontSize: "2.25rem",
                              },
                              "& .MuiFormControlLabel-label": {
                                fontSize: "0.68rem",
                                fontWeight: "light",
                                color: "#000",
                                lineHeight: "0.81rem",
                              },
                            },

                            "@media screen and (max-width: 900px) and (min-width: 501px)":
                              {
                                "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                                  {
                                    top: -12,
                                  },
                                "& .css-i4bv87-MuiSvgIcon-root": {
                                  fontSize: "2.25rem",
                                },
                              },
                            "@media screen and (min-width: 901px) and (max-width: 1024px)":
                              {
                                "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                                  {
                                    top: -29,
                                  },
                                "& .css-i4bv87-MuiSvgIcon-root": {
                                  fontSize: "2.25rem",
                                },
                              },
                            "@media screen and (min-width: 1430px)": {
                              "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                                {
                                  top: 0,
                                },
                            },
                          }}
                          control={
                            <Checkbox
                              size={"medium"}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#855BFF",
                                },
                              }}
                              name="checked1"
                              onChange={handleChange}
                            />
                          }
                          label={PersonalDataText}
                        />
                        {errors.checked1 && touched.checked1 && (
                          <CustomError>{errors.checked1}</CustomError>
                        )}
                        <br />
                        <FormControlLabel
                          labelPlacement="end"
                          sx={{
                            marginBottom: "3.5rem",
                            "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                              {
                                top: -30,
                              },
                            "& .css-i4bv87-MuiSvgIcon-root": {
                              fontSize: "2rem",
                              fill: "#92959B",
                            },

                            "& .MuiFormControlLabel-label": {
                              fontSize: "1.14rem",
                              fontWeight: "light",
                              color: "#000",
                              lineHeight: "1.06rem",
                            },
                            "@media screen and (max-width:500px)": {
                              "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                                {
                                  top: -23,
                                },
                              "& .css-i4bv87-MuiSvgIcon-root": {
                                fontSize: "2.25rem",
                              },
                              "& .MuiFormControlLabel-label": {
                                fontSize: "0.68rem",
                                fontWeight: "light",
                                color: "#000",
                                lineHeight: "0.81rem",
                              },
                            },
                            "@media screen and (max-width: 900px) and (min-width: 501px)":
                              {
                                "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                                  {
                                    top: -18,
                                  },
                                "& .css-i4bv87-MuiSvgIcon-root": {
                                  fontSize: "2.25rem",
                                },
                              },
                            "@media screen and (min-width: 901px) and (max-width: 1024px)":
                              {
                                "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                                  {
                                    top: -49,
                                  },
                                "& .css-i4bv87-MuiSvgIcon-root": {
                                  fontSize: "2.25rem",
                                },
                              },
                            "@media screen and (min-width: 1430px)": {
                              "& .css-i5ucs7-MuiButtonBase-root-MuiCheckbox-root":
                                {
                                  top: 0,
                                },
                            },
                          }}
                          control={
                            <Checkbox
                              size={"medium"}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#855BFF",
                                },
                              }}
                              name="checked2"
                              onChange={handleChange}
                            />
                          }
                          label={WarningPermission}
                        />
                        {errors.checked2 && touched.checked2 && (
                          <CustomError
                            styles={{
                              marginTop: "-43px",
                              marginBottom: "20px",
                            }}
                          >
                            {errors.checked2}
                          </CustomError>
                        )}
                        <ColorButton type="submit">
                          {footer?.form_button || ""}
                        </ColorButton>
                        {isFormSubmitted && (
                          <CustomTypography
                            variant="smallText"
                            marginTop="0.5rem"
                            color="red"
                          >
                            {i18n.language === "tr"
                              ? "Teşekkür ederiz."
                              : "Thank you."}
                          </CustomTypography>
                        )}
                      </FormGroup>
                    </FormCntainer>
                  </Form>
                )}
              </Formik>
            </Root>
          </Grid>
          <FooterBottom>
            <FooterBottomItem style={{ textAlign: "center" }}>
              {footer?.footer_bottom_text_part_one || ""}{" "}
              <a
                href="https://www.kobil.com/en/index.html"
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                {footer?.footer_kobil || ""}
              </a>{" "}
              {footer?.footer_bottom_text_part_two || ""}
            </FooterBottomItem>
            <FooterBottomItem>
              {footer?.footer_bottom_text_two || ""}{" "}
            </FooterBottomItem>
            <FooterBottomItem>
              <CustomLink
                className="buttons nav-link nav-link--mobile"
                style={{ color: "white" }}
                to={ROUTES.PRIVACY_POLICY}
              >
                {footer?.footer_bottom_text_three || ""}
              </CustomLink>
            </FooterBottomItem>
          </FooterBottom>
        </MainRoot>
        {showClarificationText && (
          <ClarificationText
            onClose={(e: React.KeyboardEvent) => toggleClarificationText(e)}
          />
        )}
      </footer>
    </>
  );
}

export default Footer;
