// eslint-disable-next-line import/no-anonymous-default-export
export const TRLanguage = {
  Navbar: {
    text: "Anasayfa",
    text2: "Hakkımızda",
    text3: "Neden Süper Uygulama?",
    text4: "Teknoloji",
    text5: "İletişim",
  },
  Home: {
    section1: {
      slogan: {
        line1: "Şehirler İçin ",
        line2: '<strong>"Güvenli Süper Uygulama Teknolojisi"</strong>',
      },
      description: {
        bold: "Platform İstanbul: ",
        light:
          "Yeni nesil, fiziksel ve dijital ekosistemleri aynı çatı altında toplayan yeni nesil öncü iş modeli",
      },
    },
    section2: {
      slogan: {
        light: `İstanbul'un`,
        bold: `"Dijital Dönüşüm Yolculuğu" `,
        light2: "başladı!",
      },
      description: {
        bold: `İstanbul Senin`,
        light:
          "İstanbul halkının ihtiyaç duyacağı tüm hizmetlere kolayca ulaşabileceği yeni nesil bir uygulamadır.",
      },
      description2: {
        light: `Vatandaşların`,
        bold: "İstanbul Büyükşehir Belediyesi",
        light2: `'nin karar alma süreçlerine katkıda bulunabileceği `,
        bold2: "Söz Senin",
        light3:
          "'den, gerekli tüm kartları bir arada tutacak cüzdana, fatura ödemelerinin yapılabileceği ",
        bold3: "İSKİ, İSPARK, ",
        light4: "ve ",
        bold4: "İGDAŞ ",
        light5:
          "uygulamalarından, ulaşımda karekod ile ödemeye kadar hayatı kolaylaştıracak bir dolu özellik ",
        bold5: "İstanbul Senin ",
        light6: "içerisinde yer alıyor.",
      },
      downloadTitle: "İstanbul Senin’i Hemen Yükle!",
    },
    section3: {
      slogan: {
        bold: "Mega trendler ",
        light: "yerel yönetimleri de değiştiriyor",
      },
      description:
        "Günümüzde, dijitalleşme, büyük veri, her gün değişen vatandaş ihtiyaç ve beklentileri, artan nüfus ve şehirleşme gibi birçok trend yerel yönetimleri de dönüştürüyor.",
      description2: `Yerel yönetim-vatandaş ilişkisi, tek yönlü bir iletişimden vatandaşların karar alma süreçlerine doğrudan katıldığı bir “birlikte yönetim” ilişkisine dönüşüyor.`,
      description3:
        "Üreten, paylaşan, katılımcı, yenilikçi, kapsayıcı, büyük küçük tüm iş sahiplerine sahip çıkan ve kentin ekonomik değerini yükselten yerel yönetim anlayışları küresel rekabette öne çıkıyor.",
    },
    section4: {
      slogan: {
        light: `Vatandaşlar `,
        bold: `"hepsi bir arada" `,
        light2: "ve ",
        bold2: `"güvenli" `,
        light3: "çözümler arıyor",
      },
      slogan_description: {
        light: `Günümüzde vatandaşlar, tüm alanlarda olduğu gibi yerel yönetim ile ilişkilerinde de sürekli değişen ihtiyaçlarına hitap eden bütünleşik, `,
        bold: ` “hepsi bir arada” `,
        light2: "ve her şeyden önemlisi ",
        bold2: `“güvenli” `,
        light3: "çözümler arıyor.",
      },
      slogan_description2: `Teknolojiden güç alan vatandaşlar yerel yönetimlerden daha fazlasını bekliyor: benzersiz ve kolay erişilebilir hizmetler, bağ kuran ilişkiler ve deneyimler, özgünlük ve şeffaflık.`,

      slogan2: {
        light: `Platform İstanbul `,
        bold: `"hepsi bir arada" `,
        light2: "ve ",
        bold2: `"güvenli"`,
        light3: "bir platform sunuyor",
      },
      slogan2_description: {
        bold: `Platform İstanbul, `,
        light: `sunduğu yeni nesil `,
        bold2: `Güvenli Süper Uygulama Teknolojisi `,
        light2: "ile yerel yönetimlerin tüm hizmetlerini ",
        bold3: "tek bir platformdan ",
        light3:
          "sunmalarını, kendi ekosistemlerini kurarak ortaklıklar yoluyla yeni katma değerli hizmetler sunmalarını sağlıyor.",
      },
      slogan2_description2: {
        light: `Platform İstanbul, belediyelerin kendi teknolojileri ve hizmetlerinin, yeni nesil `,
        bold: "Güvenli Süper Uygulama Teknolojisine kolay ve aşamalı ",
        light2: "geçiş yapmalarını sağlıyor.",
      },
    },
    section5: {
      left: [
        {
          title: `E-belediye hizmetleri`,
          description: "Tüm hizmetlere tek kimlikle tek yerden erişim",
        },
        {
          title: "İletişim",
          description: "Doğru kurgulanmış, çift yönlü iletişim kanalları",
        },
        {
          title: "Katılımcı Demokrasi",
          description: "Vatandaşların şehir yönetimine katılımı için araçlar",
        },
      ],
      right: [
        {
          title: "Güvenli Pazar Yeri",
          description:
            "İş dünyası, yerel işletmeler, girişimciler için büyüme havuzu",
        },
        {
          title: "Veri Analitiği ve Kişiselleştirme",
          description:
            "Veri yönetimi sayesinde vatandaş segmentasyonu ve özelleştirme",
        },
        {
          title: "Sosyal Yardımlaşma ve Acil Durum Yönetimi",
          description:
            "Yaygın ve mobil bir ekosistem ile verimli ve etkin bir yönetim",
        },
      ],
    },
    section6: {
      title: {
        bold: "Platform ",
        light: "İstanbul",
      },
    },
    section7: {
      title: {
        bold: "İstanbul Senin ",
        light: "Ekosistemi",
      },
      slogans: [
        {
          title: "Vatandaşlar için…",
          list: [
            {
              text: "Dijital, güvenli kimlik",
            },
            {
              text: "Tüm belediye hizmetlerine mobil ulaşım ve tek tuşla güvenli ödeme",
            },
            {
              text: "Katılımcı vatandaşlık, ortak akıl",
            },
            {
              text: "Aidiyet ve sahiplenme",
            },
            {
              text: "7/24 hizmet imkanı",
            },
            {
              text: "Sosyal yardımlaşma",
            },
          ],
        },
        {
          title: "Yerel Yönetimler için…",
          list: [
            {
              text: "Tüm belediye hizmetlerine tekil ulaşım ve ödeme sayesinde bütünsel yönetim",
            },
            {
              text: "Veri yönetimi ile öngörülü, planlı, verimli hizmet ve ek gelir",
            },
            {
              text: "Vatandaş ile kişiselleştirilmiş, doğrudan ve etkin iletişim",
            },
            {
              text: "Katılımcı, yerel demokrasi modeli",
            },
            {
              text: "Aidiyet",
            },
          ],
        },
        {
          title: "Özel Sektör için...",
          list: [
            {
              text: "Yenilikçi İş Modelleri",
            },
            {
              text: "Anlamlandırılmış veri ile hedef kitleye etkin ulaşım",
            },
            {
              text: "Altyapı ve platform desteği",
            },
            {
              text: "Güvenilir trafik",
            },
            {
              text: "Markalaşma",
            },
          ],
        },
      ],
    },
    section8: {
      slogan: {
        line1: "Dijitalleşen kimlik ile ",
        line2: "<strong>temassız ve sonsuz</strong> ",
        line3: "sayıda hizmete ",
        line4: "<strong>güvenli erişim</strong>",
      },
    },
  },
  About_us: {
    section1: {
      description: {
        bold: "Platform İstanbul, ",
        light:
          "paydaşlarının potansiyellerini maksimum düzeyde kullanmalarını ve platform ekonomisinde ",
        bold2: "hep birlikte kazanmalarını sağlamak ",
        light2: "üzere kurulmuş, yeni nesil bir teknoloji şirketidir.",
      },
      slogan: {
        bold: "Güvenli Süper Uygulama ",
        light: "Çözümleri",
      },
    },
    section2: {
      description: {
        light:
          "Yerel yönetimler için yüksek güvenlikli süper uygulama çözümleri sunan ",
        bold: "Platform İstanbul, ",
        light2:
          "tüm paydaşların katılımıyla güçlenen, fiziksel ve dijital ekosistemleri aynı çatı altında toplayan öncü bir iş modeli sunmaktadır.",
      },
      description2: {
        bold: "Platform İstanbul",
        light:
          ", dijital kimlik ve yüksek güvenlikli veri teknolojisinde öncü olarak kabul edilen",
        bold2: "KOBIL GROUP,",
        light2: "tarafından desteklenmektedir.",
      },
    },
  },
  Why_super_app: {
    section1: {
      slogan: {
        light: "Sonsuz olanaklar sunan",
        bold: "yeni nesil yerel yönetim platformu",
        light2: "kurun",
      },
      description:
        "Sonsuz olanaklar sunan yeni nesil yerel yönetim platformu kurarak, herkesin kazandığı bir platformun orkestra şefi olun.",
    },
    section2: {
      slogan: {
        light: "Basit,",
        light2: "bütünleştirici,",
        light3: "yeni nesil =",
        bold: "Güvenli Süper Uygulama",
      },
      description1: "Süper Uygulama, içinde onlarca uygulamanın",
      description2: "olduğu bir şemsiye uygulamadır.",
      description3: "Entegre ve verimli bir deneyim sunduğu için",
      description4: "insanların her gün kullanacağı, içindeki farklı",
      description5: "uygulamalar arasında sinerji yaratarak katma",
      description6: "değerli hizmetler sunan kapalı bir ekosistemdir.",
    },

    section3: {
      slogan:
        "Bütünsel bir dijital dönüşüm ile verimlilik ve inovasyon sağlayın",
      slogan_description:
        "Belediye, bağlı kuruluş ve iştirak şirketlerinin kurumsal BT altyapısını yarının dünyasına hazır hale getirin.",
      slogan_item: "Tekil, dijital, güvenli kimlik",
      slogan_item2: "Anlamlandırılmış veri yönetimi",
      slogan_item3: "Güçlü iletişim, koordinasyon ve işbirliği",
      slogan_item4: "Etkin ve fonksiyonel kaynak kullanımı",
      slogan_item5: "Güçlü ve verimli iş süreçleri",
      slogan_item6: "Yeni nesil hizmetler",

      slogan2: "Vatandaşlar ile etkin iletişim kurun ve duygusal bağ yaratın",
      slogan2_description:
        "İnsan odaklı, yeni nesil bir katılımcı demokrasi platformu kurun.",
      slogan2_item: "Çift yönlü, kişiselleştirilmiş, anlık iletişim",
      slogan2_item2: "Hızlı, kapsayıcı, şeffaf bir yerel yönetişim sistemi",
      slogan2_item3: "Karar alma süreçlerine eşit ve etkin katılım",
      slogan2_item4: "Artan hemşehrilik ve aidiyet duygusu",

      slogan3: "Şebeke etkisi ile şehir ekonomisini büyütün.",
      slogan3_description:
        "Şehrin enerjisini, yaratıcılığını, çeşitliliğini ve ekonomisini canlandırın.",
      slogan3_item: "Şehir ekonomisine ve istihdama katkı",
      slogan3_item2: "Kentin yaratıcı potansiyeline ve girişimciliğe destek",
      slogan3_item3: "Yeni gelir kaynakları",
      slogan3_item4: "Açık inovasyon",

      slogan4:
        "Sosyal hizmetlere erişimi ve sosyal yardımlaşmayı kolaylaştırın.",
      slogan4_description:
        "Sosyal ihtiyaçları eşit ve kapsayıcı bir şekilde karşılayarak refah seviyesi yüksek ve paylaşan bir şehir oluşturun.",
      slogan4_item:
        "Herkesin kolay erişebileceği ve güvenle kullanabileceği bir uygulama",
      slogan4_item2: "Belediye hizmetlerine kolay, pratik, rahat ulaşım",
      slogan4_item3: "Sosyal yardımlaşma ve topluluk oluşturma imkanı",
      slogan4_item4: "Afet ve kriz yönetimi",

      slogan5:
        "Girişimcilik ekosistemine destek vererek yerel ekonomiyi büyütün",
      slogan5_description:
        "Start-up ve KOBİ’lere erişimi kolay bir ekosistem sunarak kaldıraç olun.",
      slogan5_item: "Kamu-özel işbirlikleri (PPP)",
      slogan5_item2:
        "Şehir markasının ve teknolojinin sağladığı itibarlı, güvenilir trafik",
      slogan5_item3: `Yatırımcı ortamları (B2B eşleştirme, “kickstarter”, kitle kaynak kullanımı)`,
      slogan5_item4: "Markalaşma ve referans",
      slogan5_item5: "Büyük oyunculara karşı ayakta durma imkanı",
    },
  },
  Technology: {
    section1: {
      slogan: {
        bold: "KOBIL Güvenli ",
        light: "Süper Uygulama Teknolojisi",
      },
      description: {
        light:
          "60 milyondan fazla kullanıcının Türkiye ve Avrupa’da güvenli finansal işlem yapabilmesini sağlayan ",
        bold: "KOBIL ",
        light2: "teknolojik alt yapısını kullanan ",
        bold2: "Platform İstanbul, ",
        light3: "yeni nesil ",
        bold3: "Süper Uygulama Teknolojisi ",
        light4:
          "ile müşterilerine yüksek güvenlikli, kesintisiz, kolay kullanımlı platform iş modeli kullanımı sağlar.",
      },
    },
    section2: {
      identity_slogan: "Güvenli işlemlere güç verir.",
      identity_description:
        "Yüksek güvenlikli dijital kimlik çözümü sunan patentli ürünümüz aracılığıyla müşterilerinize güven verin.",

      pay_slogan: "Anlamlı etkileşimlere güç verir",
      pay_description: "Basit, etkili, duyarlı ve güvenli iletişim sağlayın.",

      connect_slogan: "Kolay ödeme işlemlerine güç verir",
      connect_description:
        "En karmaşık işlemleri bile güçlü ve güvenli ödeme sistemi ile kolaylaştırır.",
    },
    section3: {
      slogan: "KOBIL Güvenlik Teknolojisi",
      description:
        "Çok katmanlı ve patentli teknolojimiz, en yüksek düzeyde gizlilik ve güvenlik sağlar. KVKK, GDPR gibi ilgili tüm normlarla her zaman uyumludur.",
    },
  },
  Footer: {
    title: "Bize Ulaşın",

    address: {
      line1: "Sanayi Mahallesi, Teknopark Bulvarı, No: 1/7C",
      line2: "Yeditepe Üniversitesi Teknoloji Üssü, Kat: 3,",
      line3: " İç Kapı No: 303, 34906 Pendik/İstanbul",
    },

    email: "info@platformistanbul.ist",

    form_title: "Yeni projelerden haberdar olmak için:",
    form_input_placeholder1: "Ad - Soyad",
    form_input_placeholder2: "E-posta",
    form_permission: {
      text: "Kişisel verilerimin Aydınlatma Metni uyarınca yurtiçi ve yurtdışındaki üçüncü kişilere aktarılmasına izin veriyorum ve bu konuda gereği gibi bildilendirildiğimi kabul ediyorum. ",
      underline_text: "Aydınlatma Metni’ni görüntüle",
    },
    form_permission2: {
      text: "Tarafıma, Platform İstanbul Teknoloji A.Ş. ürün ve hizmetlerine ilişkin tanıtım, bülten, bilgilendirme, reklam ve pazarlama amacıyla e-posta yoluyla ticari elektronik ileti gönderilmesine ve kişisel verilerimin bu amaçla Aydınlatma Metni kapsamında işlenmesine izin veriyorum. ",
      underline_text: "Aydınlatma Metni’ni görüntüle",
    },
    form_button: "Abone Ol",
    footer_bottom_text_part1: "Platform İstanbul bir",
    footer_kobil: "KOBIL GROUP",
    footer_bottom_text_part2: "şirketidir.",
    footer_bottom_text2: "© 2021 Platform İstanbul",
    footer_bottom_text3: "Gizlilik Politikamız",
  },
  ClarificationText: {
    title: `KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ`,
    subtitle1: `Veri Sorumlusu`,
    subtitle2: `Platform İstanbul Teknoloji Anonim Şirketi`,
    address: `Sanayi Mah. Teknopark Bulv. 1/7C, Yeditepe Üniversitesi Teknoloji Üssü, K:3, İç Kapı:303, İstanbul`,
    content1: `Veri Sorumlusu sıfatıyla Platform İstanbul Teknoloji Anonim Şirketi (“PİTAŞ”) olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) ve ilgili mevzuat ve yasal düzenlemeler doğrultusunda; kişisel verilerinizin işlenmesi, saklanması ve aktarılmasına ilişkin bilgilendirmek isteriz. Şirketimiz ile paylaştığınız kişisel verileriniz KVKK’ya uygun şekilde, faaliyet ve hizmet amaçlarımız ile bağlantılı ve ölçülü olarak işlenebilecek, yurt içi ve yurt dışındaki üçüncü kişilere aktarılabilecek, saklanacak, profilleme için kullanılabilecek ve sınıflandırılabilecektir.`,
    content2_title: `Kişisel Verilerinizin İşlenme Amaçları, Hukuki Sebepleri ve Toplama Yöntemleri Nelerdir?`,
    content2_text1: `Zaman zaman özel nitelikli kişisel veriler de dahil olmak üzere kişisel verileriniz, Şirketimiz tarafından; Şirketimizin kanuni yükümlülüklerini yerine getirebilmesi, bir hakkın tesisi, korunması yahut kullanılması için veri işlemenin zorunlu olması, Şirketimiz ile aranızdaki ticari sözleşmesel ilişkinin kurulması ve ifası, Şirketimizin meşru amaçları için yahut bu konuda onay vermiş olmanız halinde tarafınıza Şirketimize ait ürün ve hizmetlerin tanıtımı ve pazarlanması amacıyla ticari elektronik ileti gönderimi için kişisel verilerinizin işlenmesine gerek olması halinde toplanılacak ve kullanılacaktır.`,
    content2_text2: `Bu yöntemlerle toplanan kişisel verileriniz KVKK’nın 5’inci ve 6’ncı maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında Aydınlatma Metni’nde yer alan maddelerde belirtilen amaçlarla işlenebilmekte ve aktarılabilmektedir.`,
    content3_title: `Kişisel Verileriniz Kimlere ve Hangi Amaçlarla Aktarılabilecektir?`,
    content3_text1: `Kişisel verileriniz; Şirketimizin kanuni yükümlülüklerini yerine getirebilmesi, bir hakkın tesisi, korunması yahut kullanılması için veri işlemenin zorunlu olması, Şirketimiz ile aranızdaki ticari sözleşmesel ilişkinin kurulması ve ifası, Şirketimizin meşru amaçları için yahut bu konuda onay vermiş olmanız halinde tarafınıza Şirketimize ait ürün ve hizmetlerin tanıtımı ve pazarlanması amacıyla ticari elektronik ileti gönderimi amacıyla, şirket yetkililerine, iş ortaklarına ve hizmet sağlayıcılara, iştiraklerimize ve kanunen yetkili kamu kurumları ve özel kişilere aktarılabilecektir. KVKK’nın 8’inci maddesinde belirtilen kurallara uyularak ve gerekli teknik ve idari tedbirler alınarak, sadece ilgili amacın gerçekleşmesi için gerekli olduğu ölçüde; aktarılabilecektir. Kişisel verileriniz; teknoloji alanındaki altyapı tedarikçilerimiz tarafından sağlanan / kullanımımıza sunulan programlarımıza ve / veya sistemlerimize kaydedilebilecektir.`,
    content4_title: `İlgili Kişi Olarak Haklarınız Nelerdir?`,
    content4_text1: `KVKK ve ilgili mevzuat kapsamında kişisel verilerinize ilişkin olarak;`,
    content4_list_item1: "Kişisel verilerinizin işlenip işlenmediğini öğrenme,",
    content4_list_item2:
      "Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,",
    content4_list_item3: `Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,`,
    content4_list_item4: `Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,`,
    content4_list_item5: `Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,`,
    content4_list_item6: `KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,`,
    content4_list_item7: `Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok edilmesini talep ettiğinizde, bu durumun kişisel verilerinizi aktardığımız üçüncü kişilere bildirilmesini isteme,`,
    content4_list_item8: `İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve`,
    content4_list_item9: `Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın giderilmesini talep etme`,
    content5_title: `Haklarınızı Nasıl Kullanabilirsiniz?`,
    content5_text1: `Kişisel verileriniz ile ilgili başvuru ve taleplerinizi dilerseniz web sitemizde yer alan başvuru formu aracılığıyla aracılığıyla;`,
    content5_list_item1_part1: `kimlik teyidinizin yapılması kaydıyla güvenli elektronik imza ya da mobil imzanız ile`,
    content5_list_item1_part2: `adresine elektronik posta göndererek`,
    info_mail: "info@platformistanbul.ist",
    content5_list_item2: `Islak imzalı ve kimliğinizi tevsik edici belgeler ile Sanayi Mah. Teknopark Bulv. 1/7C, Yeditepe Üniversitesi Teknoloji Üssü, K:3, İç Kapı:303, İstanbul adresine göndererek,`,
    content5_text2: `Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca, İlgili Kişi’nin, başvurusunda isim, soyisim, başvuru yazılı ise imza, T.C. kimlik numarası, (başvuruda bulunan kişinin yabancı olması halinde uyruğu, pasaport numarası veya varsa kimlik numarası), tebligata esas yerleşim yeri veya iş yeri adresi, varsa bildirime esas elektronik posta adresi, telefon numarası ve faks numarası ile talep konusuna dair bilgilerin bulunması zorunludur.`,
    content5_text3: `İlgili Kişi, yukarıda belirtilen hakları kullanmak için yapacağı ve kullanmayı talep ettiği hakka ilişkin açıklamaları içeren başvuruda talep edilen hususu açık ve anlaşılır şekilde belirtmelidir. Başvuruya ilişkin bilgi ve belgelerin başvuruya eklenmesi gerekmektedir.`,
    content5_text4: `alep konusunun başvuranın şahsı ile ilgili olması gerekmekle birlikte, başkası adına hareket ediliyor ise başvuruyu yapanın bu konuda özel olarak yetkili olması ve bu yetkinin belgelendirilmesi (vekâletname) gerekmektedir.`,
    content5_text5: `Ayrıca başvurunun kimlik ve adres bilgilerini içermesi ve başvuruya kimliği doğrulayıcı belgelerin eklenmesi gerekmektedir.`,
    content5_text6: `Bu amaçlarla yaptığınız başvurunun ek bir maliyet gerektirmesi durumunda, Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifedeki ücret tutarını ödemeniz gerekebilir.`,
    content6_title: `Kişisel Verilerinizin İşlenmesine İlişkin Talepleriniz Ne Kadar Sürede Cevaplanır?`,
    content6_text1: `Kişisel verilerinize ilişkin hak talepleriniz değerlendirilerek, bize ulaştığı tarihten itibaren en geç 30 (otuz) gün içerisinde cevaplanır. Başvurunuzun olumsuz değerlendirilmesi halinde gerekçeli ret sebepleri başvuruda belirttiğiniz adrese elektronik posta veya posta yolu başta olmak üzere İlgili Kişi Başvuru Formu’nda seçilen usullerinden biri ile gönderilir.`,
  },

  Privacy_policy: {
    title: "GİZLİLİK POLİTİKASI",

    entrance: [
      {
        text: "Şirketimiz, internet sitemizi ziyaret edenlerin gizliliğini korumayı ilke olarak benimsemiş, bilgilerinizin kullanımı ve korunmasına ilişkin olarak sizleri bilgilendirmek istemiştir. Bu politikayı kabul etmiyorsanız, internet sitemizi kullanmamanızı ve ziyaret etmemenizi öneririz.",
      },
      {
        text: "Şirketimiz, kişisel verilerin hukuka uygun işlenmesi adına 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK“) hükümlerine ve uygun düştüğü ölçüde Avrupa Birliği Veri Koruma Tüzüğü (“GDPR“) hükümlerine uyum sağlamak için gerekli her türlü tedbir ve önlemleri almaktadır. Ancak bu önlemlere rağmen internet açık bir sistem olup Şirket, yetkisi olmayan üçüncü kişilerin Şirket’in almış olduğu teknik önlemleri aşamayacağını taahhüt etmemektedir.",
      },
      {
        text: "Bu politikaya ilişkin olası değişiklikler bu sayfada yayımlanacaktır. Bu çerçevede, hangi bilgilerin saklandığına ve bu bilgilerin nasıl kullanıldığına dair güncel olarak bilgi almanıza olanak sağlanacaktır.",
      },
      {
        text: "İnternet sitemiz üzerinde kontrolümüzün bulunmadığı sair internet sitelerine bağlantılar içerebilir. Şirketimiz, diğer internet sitelerinin gizlilik kurallarından veya sair uygulamalarından sorumlu değildir. Bu tür internet sitelerinin gizlilik kurallarını gözden geçirmenizi ve sizin bilgilerinizi nasıl topladıklarını, kullandıklarını ve paylaştıklarını incelemenizi tavsiye ederiz. Ayrıca, Şirketimiz diğer internet sitelerinin içeriklerinin doğruluğu hakkında herhangi bir beyan ve taahhütte bulunmamaktadır.",
      },
      {
        text: "İnternet sitemizin içeriği, azami doğrulukla oluşturulmuştur. Ancak içeriğin doğruluğunu, eksiksizliğini ve güncelliği için garanti veremeyiz. Bir hizmet sağlayıcı olarak, sitelerde bulunan bize ait içeriğin mevzuata uygunluğundan sorumluyuz.",
      },
    ],

    subtitle1: "KİŞİSEL VERİLER",
    subtitle2: "ÇEREZLER",
    subtitle3: "Google Analytics ile Internet Analizi:",
    subtitle4: "LinkedIn:",
    subtitle5: "YouTube:",
    subtitle6: "Veri Sahibi Haklarınız:",

    personal: [
      {
        text: "Gönüllü olarak Şirketimize vermeyi tercih etmediğiniz sürece web sitemiz aracılığıyla sizin rızanız olmadan kişisel verilerinizin korunması için uygulanabilen mevzuat kapsamında açık rıza gerekmeyenler dışında, Şirketimiz sizin hakkınızda herhangi bir kişisel veri toplamaz.",
      },
      {
        text: "İnternet sitemizi kullanarak ya da kişisel verilerinizi ileterek, bu politikaya, internet sitemizde yer alan aydınlatma metnine ve yürürlükteki mevzuata uygun olarak kişisel verilerinizin toplanmasına, kullanılmasına, işlenmesine ve bizimle paylaşılmasına onay vermektesiniz. Kişisel verilerinizin bu politikada ve internet sitemizde yer alan aydınlatma metninde belirtildiği şekilde, toplanmasına, kullanılmasına, işlenmesine ve bizimle paylaşılmasına onay vermemeniz durumunda, internet sitemizi ve Bize Ulaşın formlarını kullanmamanız rica olunur.",
      },
      {
        text: "Kimlik ve iletişim bilgilerinizi web sitemizde yer alan “Bize ulaşın” isimli iletişim formu üzerinden veya ilgili sekmede yer alan çağrı merkezi numaralarımız aracılığıyla bizimle paylaşmış olabilirsiniz. Söz konusu kişisel verilerinizi, talebinize cevap vermek amacıyla kullanacağız.",
      },
      {
        text: "Verilerinizin işlenmesi ve kullanımı, mevcut talebinizi karşılamak için işlemenin gerekli olması halinde KVKK hükümleri ve şirketimiz menfaati gereğince gerçekleştirilecektir.",
      },
    ],

    cookies: [
      {
        text: "İnternet sitemize eriştiğiniz zaman, kişisel olmayan verileri (örneğin, kullanılan İnternet tarayıcısı ve işletim sisteminin türü, geldiğiniz internet sitesinin alan adı, ziyaret sayısı, sitede harcanan ortalama süre, görüntülenen sayfalar) otomatik olarak toplayabiliriz. İnternet sitemizin içeriğini geliştirmek amacıyla bu verileri kullanabilir ve dünya çapındaki iş ortaklarımız ve hissedarlarımız ile paylaşabiliriz.",
      },
      {
        text: "Çerezler, internet sitemizi kullanmak için gerekli olan ve tarayıcınız tarafından sabit diskinize geçici olarak depolanan küçük veri birimleridir. Bir çerez genellikle, rastgele şekilde oluşturulmuş bir sayıdan oluşan ve cihazınıza depolanan benzersiz bir tanımlayıcı içerir. Bu tanımlayıcıların bazıları internet sitesi oturumunuz sona erdiğinde geçerliliğini yitirirken, bazıları ise daha uzun süreler boyunca cihazınızda kalabilir. Teknik açıdan gerekli çerezler otomatik olarak kullanılacaktır. Diğer çerezler (ya da benzeri teknolojiler) yalnızca ön onayınız üzerine uygulanacaktır. Tarayıcınızdaki ayarlardan çerezlerin yüklenmesini engelleyebilirsiniz, ancak bunun sonucunda internet sitemizin bazı fonksiyonlarını kullanamayabilirsiniz.",
      },
    ],

    internetAnalysis: [
      {
        text: "İnternet sitemiz, Google Inc.'in ('Google') web analiz hizmeti Google Analytics’i kullanabilir. Google Analytics, bilgisayarınızda saklanabilen ve internet sitesi kullanım analizine olanak sağlayan metin dosyalarını yani çerezlerini kullanır. Çerezler (kısaltılmış IP adresiniz dahil) ile üretilen internet sitesinin kullanımı hakkında bilgiler, Amerika Birleşik Devletleri’ndeki Google sunucularına aktarılır ve burada depolanır. Google bu bilgileri internet sitesinin kullanımını değerlendirmek, internet sitesi sağlayıcı için internet sitesi aktivitelerini gösteren raporlar hazırlamak ve internet sitesi kullanımı ve internet ile bağlantılı diğer hizmetleri sunmak için kullanır. Ayrıca, Google bu bilgileri yasal bir zorunluluk veya bu verilerin Google’ın talebi üzerine üçüncü taraflarca işlenmesi söz konusuysa, üçüncü taraflara aktarabilir. Google hiçbir durumda IP adresinizle Google kaynaklı diğer verileri birleştirmeyecektir.",
      },
      {
        text: "Sitelerin sağlayıcısı olarak aktarılan verilerin içeriğinden ve Google tarafından kullanımı hakkında bilgimizin olmadığını belirtiriz. Daha fazla bilgiye Google’ın Gizlilik Politikasından ulaşabilirsiniz.",
      },
    ],

    linkedin:
      "İnternet sitemiz, LinkedIn ağının fonksiyonlarını kullanabilir. Sağlayıcı, 2029 Stierlin Court, Mountain View, CA 94043, Amerika Birleşik Devletleri merkezli LinkedIn Corporation’dır. LinkedIn fonksiyonunun kullanıldığı sitelerimize her erişim sağlandığında, LinkedIn sunucularına bağlantı olacaktır. LinkedIn, IP adresinizle sitelerimizi ziyaret ettiğinizden haberdar olacak. LinkedIn’in 'Tavsiye Et-Butonu'na bastığınızda ve LinkedIn’e giriş yaptığınızda, LinkedIn’in internet sitemizi ziyaretiniz şahsen size ve kullanıcı hesabınıza özgülenecektir. Sitelerin sağlayıcısı olarak aktarılan verilerin içeriğinden ve LinkedIn tarafından kullanımı hakkında bilgimizin olmadığını belirtiriz. Daha fazla bilgiye LinkedIn’in Gizlilik Politikasından ulaşabilirsiniz.",

    youtube:
      "İnternet sitemiz, YouTube sitesi için Google eklentilerini kullanabilir. Sağlayıcı, 901 Cherry Ave., San Bruno, CA 94066, Amerika Birleşik Devletleri merkezli YouTube, LLC’dir. Oluşum içi YouTube pluginleri bulunan web sitelerimizden birini kullanıyorsanız, YouTube serverlarına kurulu bağlantı olacaktır. Bu yolla YouTube sunucuları internet sitemizden ziyaret ettiğinizden haberdar olacaktır. YouTube hesabınıza girdiğinizde, YouTube tarama davranışınızı kişisel profilinize özgüleyecektir. Sitelerin sağlayıcısı olarak aktarılan verilerin içeriğinden ve Youtube tarafından kullanımı hakkında bilgimizin olmadığını belirtiriz. Daha fazla bilgiye Youtube’un Gizlilik Politikasından ulaşabilirsiniz.",

    dataSubjects: [
      {
        text: "KVKK’nın 11’inci maddesi taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ hükümlerine göre internet sitemizde yer alan başvuru formu aracılığı ile dilerseniz, Sanayi Mah. Teknopark Bulv. 1/7C-303, Pendik, İstanbul adresine bizzat veya posta yolu ile veya veyahut güvenli elektronik imza ya da mobil imzanız ile info@platformistanbul.ist eposta adresine kimlik teyidinizin yapılması kaydıyla iletebilirsiniz. Şirketimiz, başvuru taleplerinizi, KVKK’nın 13'üncü maddesine uygun olarak, talebin niteliğine göre ve en geç 30 (otuz) gün içinde sonuçlandıracaktır.",
      },
      {
        text: "Bu amaçlarla yaptığınız başvurunun ek bir maliyet gerektirmesi durumunda, Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifedeki ücret tutarını ödemeniz gerekebilir. Talebin reddedilmesi halinde, ret nedeni/nedenleri yazılı olarak veya elektronik ortamda gerekçelendirilecektir.",
      },
    ],
  },
};
