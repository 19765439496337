import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {
  AppBar,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import NavbarIcon from "../../assets/NavbarIcon.svg";
import "../Navbar/style.css";
import CustomLink from "../shared/CustomLink";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useNavbarLogic } from "../../hooks";

type Anchor = "right";

const Navbar = () => {
  const [state, setState] = useState(false);
  const { language, t, handleChange } = useNavbarLogic();

  const pages = [
    {
      name: t("Navbar.text"),
      path: ROUTES.HOME,
    },
    {
      name: t("Navbar.text2"),
      path: ROUTES.ABOUT,
    },
    {
      name: t("Navbar.text3"),
      path: ROUTES.WHY_SUPER_APP,
    },
    {
      name: t("Navbar.text4"),
      path: ROUTES.TECHNOLOGY,
    },
    {
      name: t("Navbar.text5"),
      href: "#footer",
    },
  ];

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      setState(!state);
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "71px",
        }}
        className="mobile-nav-list"
      >
        {pages.map(({ name, path, href }) =>
          path ? (
            <CustomLink
              key={name}
              className="buttons nav-link nav-link--mobile"
              activeClassName="nav-link--active"
              to={path}
            >
              {name}
            </CustomLink>
          ) : (
            <a key={name} href={href} className="nav-link nav-link--mobile">
              {name}
            </a>
          )
        )}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      sx={{ background: "white", height: "100px", justifyContent: "center" }}
    >
      <Container maxWidth="xl">
        <Grid className="section-max-width">
          <Toolbar className="toolbar">
            <Box maxWidth={{ xs: "155px", md: "200px" }}>
              <NavLink to="/">
                <img
                  src={NavbarIcon}
                  alt="Platform Istanbul Logo"
                  title="Platform Istanbul Logo"
                />
              </NavLink>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                onClick={toggleDrawer("right", true)}
                size="large"
                aria-label="open drawer"
                color="default"
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                anchor="right"
                open={state}
                onClose={toggleDrawer("right", false)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "inherit",
                  }}
                >
                  <div>
                    <IconButton
                      sx={{
                        float: "right",
                        margin: "17px",
                        width: "24px",
                        height: "24px",
                      }}
                      onClick={toggleDrawer("right", false)}
                    >
                      <CloseIcon />
                    </IconButton>

                    {list("right")}
                  </div>

                  <Grid className="img-container" display={"block"}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      margin={"100px 40px"}
                    >
                      <FormControl variant="standard">
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={language}
                          onChange={handleChange}
                          style={{ fontSize: "12px", border: "none" }}
                        >
                          <MenuItem selected value={"tr"}>
                            TR
                          </MenuItem>
                          <MenuItem value={"en"}>EN</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <img src={NavbarIcon} alt="Platform Istanbul" />
                  </Grid>
                </Box>
              </Drawer>
            </Box>

            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
              className="nav-link-container"
            >
              {pages.map(({ name, path, href }) =>
                path ? (
                  <CustomLink
                    key={name}
                    className="nav-link"
                    activeClassName="nav-link--active"
                    to={path}
                  >
                    {name}
                  </CustomLink>
                ) : (
                  <a key={name} href={href} className="nav-link">
                    {name}
                  </a>
                )
              )}
              <Box>
                <FormControl variant="standard">
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={language}
                    onChange={handleChange}
                    style={{ fontSize: "14px", border: "none" }}
                  >
                    <MenuItem selected value={"tr"}>
                      TR
                    </MenuItem>
                    <MenuItem value={"en"}>EN</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Toolbar>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Navbar;
