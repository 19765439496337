// eslint-disable-next-line import/no-anonymous-default-export

export const ENLanguage = {
  Navbar: {
    text: "Home",
    text2: "About Us",
    text3: "Why Super App?",
    text4: "Technology",
    text5: "Contact Us",
  },
  Home: {
    section1: {
      slogan: {
        line1: '<strong>"Secure Super App Technology"</strong>',
        line2: "for cities",
      },
      description: {
        bold: "Platform Istanbul: ",
        light:
          "A pioneering business model that unites new and next generation physical and digital ecosystems under the same roof",
      },
    },
    section2: {
      slogan: {
        light: `Istanbul's `,
        bold: `"Digital Transformation Journey" `,
        light2: "Has Started!",
      },
      description: {
        bold: `Istanbul Senin (“Istanbul is yours”) `,
        light:
          "is a next-generation application where the citizens of Istanbul can easily access all the services they need.",
      },
      description2: {
        light: `From `,
        bold: "Söz Senin, ",
        light2: `where citizens can contribute to the decision-making processes of the `,
        bold2: "Istanbul Metropolitan Municipality, ",
        light3:
          "to the secure wallet that keeps all the necessary cards, from the ",
        bold3: "İSKİ, İSPARK, ",
        light4: "and ",
        bold4: "İGDAŞ ",
        light5:
          "applications where utility payments can be made, to public transportation payments via QR code, ",
        bold5: "İstanbul Senin ",
        light6: "has many features that make life easier.",
      },
      downloadTitle: "Download Istanbul Senin Now!",
    },
    section3: {
      slogan: {
        bold: "Megatrends ",
        light: "are changing local governance as well.",
      },
      description:
        "In today’s world, global trends such as digitalization, big data, ever-changing citizen needs & expectations, increasing population, and urbanization transform local administrations as well.",
      description2: `The local government-citizen relationship transforms from one-way communication to a "co-governance" where citizens directly participate in decision-making.`,
      description3:
        "Local governance approaches that are productive, sharing, innovative, inclusive, protecting all business owners, large and small, and increasing the city's economic value, come to the fore in global competition.",
    },
    section4: {
      slogan: {
        light: `Citizens seek `,
        bold: `"all-in-one" `,
        light2: "and ",
        bold2: `"secure" `,
        light3: "solutions.",
      },
      slogan_description: {
        light: `Today, citizens are looking for integrated, `,
        bold: `"all-in-one" `,
        light2: "and above all ",
        bold2: `"secure" `,
        light3:
          "solutions that address their ever-changing needs in their relations with local governments.",
      },
      slogan_description2: `Powered by technology, citizens expect more from local governments: unique and easily accessible services, connection building relationships and experiences, authenticity and transparency.`,

      slogan2: {
        light: `Platform Istanbul offers an `,
        bold: `"all in one" `,
        light2: "and ",
        bold2: `"secure"`,
        light3: "platform",
      },

      slogan2_description: {
        bold: "Platform Istanbul",
        light: `With its new generation `,
        bold2: `Secure Super Application Technology, `,
        light2: `enables local administrations to provide all their services from `,
        bold3: "a single platform ",
        light3:
          "and offer new value-added services through partnerships by establishing their ecosystems.",
      },
      slogan2_description2: {
        light: `Platform Istanbul enables municipalities to `,
        bold: "make an easy and gradual ",
        light2:
          "transition of their technology and services to the next generation ",
        bold2: "Secure Super Application Technology.",
      },
    },
    section5: {
      left: [
        {
          title: `E-municipality Services`,
          description:
            "Access to all services from a single point with a single identity.",
        },
        {
          title: "Communication",
          description: "Correctly constructed two-way communication channels",
        },
        {
          title: "Participatory Democracy",
          description: "Tools for citizen participation in city government",
        },
      ],
      right: [
        {
          title: "Safe Marketplace",
          description:
            "A growth pool for business, local businesses, entrepreneurs",
        },
        {
          title: "Data Analytics and Personalization",
          description:
            "Citizen segmentation and customization through data management",
        },
        {
          title: "Social Assistance and Emergency Management",
          description:
            "Effective and efficient management with a widespread and mobile ecosystem",
        },
      ],
    },
    section6: {
      title: {
        bold: "Platform ",
        light: "Istanbul",
      },
    },
    section7: {
      title: {
        bold: "Istanbul Senin ",
        light: "Ecosystem",
      },
      slogans: [
        {
          title: "For citizens...",
          list: [
            {
              text: "Secure, digital identity",
            },
            {
              text: "Mobile access to all municipal services and secure payment with one click",
            },
            {
              text: "Participatory citizenship",
            },
            {
              text: "The sense of belonging and ownership",
            },
            {
              text: "24/7 service opportunity",
            },
            {
              text: "Welfare",
            },
          ],
        },
        {
          title: "For Local Administrations...",
          list: [
            {
              text: "Holistic management through a single access and payment for all municipal services",
            },
            {
              text: "Predictive, planned, efficient service and additional income with data management",
            },
            {
              text: "Personalized, direct, and effective communication with citizens",
            },
            {
              text: "Participatory, local democracy model",
            },

            {
              text: "The sense of belonging",
            },
          ],
        },
        {
          title: "For the Private Sector...",
          list: [
            {
              text: "Innovative Business Models",
            },
            {
              text: "Effective access to the target audience with meaningful data",
            },
            {
              text: "Infrastructure and platform support",
            },
            {
              text: "Reliable traffic",
            },
            {
              text: "Branding",
            },
          ],
        },
      ],
    },
    section8: {
      slogan: {
        line1: "<strong>Secure access to</strong>",
        line2: "<strong>contactless and countless</strong> ",
        line3: "number of services ",
        line4: "with digitalized identity",
      },
    },
  },
  About_us: {
    section1: {
      description: {
        bold: "Platform Istanbul,",
        light:
          " is a new generation technology company founded to enable its stakeholders to use their potential at the maximum and ",
        bold2: "win together in the platform economy.",
        light2: "",
      },
      slogan: {
        bold: "Secure Super Application",
        light: "Solutions",
      },
    },
    section2: {
      description: {
        light: "",
        bold: "Platform Istanbul",
        light2:
          ",which offers high-security super application solutions for local administrations, provides a pioneering business model that unites physical and digital ecosystems under the same roof, strengthened with the participation of all stakeholders.",
      },
      description2: {
        bold: "Platform Istanbul",
        light: "is supported by",
        bold2: "KOBIL GROUP,",
        light2:
          "considered as a pioneer in digital identity and high-security data technology.",
      },
    },
  },
  Why_super_app: {
    section1: {
      slogan: {
        light: "Build ",
        bold: "a next-generation local governance platform",
        light2: "with endless possibilities",
      },
      description:
        "Become the conductor of a platform where everyone wins by building a next-generation local govervance platform that offers endless possibilities.",
    },
    section2: {
      slogan: {
        light: "Simple,",
        light2: "integrative,",
        light3: "next-generation =",
        bold: "Secure Super App",
      },
      description1: "Super App is an composed and inclusive app ",
      description2: "with dozens of apps in it.",
      description3: "It is a closed-circuit ecosystem that provides",
      description4: " value-added services by creating synergies",
      description5: "between various applications that people will use on",
      description6:
        " a daily basis because it offers an integrated and efficient experience.",
    },

    section3: {
      slogan:
        "Provide efficiency and innovation with a holistic digital transformation",
      slogan_description:
        "Prepare the corporate IT infrastructure of municipalities, subsidiaries, and affiliates for the world of tomorrow.",
      slogan_item: "Unique, digital, secure identity",
      slogan_item2: "Meaningful data management",
      slogan_item3: "Strong communication, coordination, and collaboration",
      slogan_item4: "Practical and functional resource use",
      slogan_item5: "Powerful and efficient business processes",
      slogan_item6: "Next-generation services",

      slogan2:
        "Communicate effectively and create emotional bonds with citizens",
      slogan2_description:
        "Build a people-oriented, new generation participatory democracy platform.",
      slogan2_item: "Two-way, personalized, instant communicatio",
      slogan2_item2: "A fast, inclusive, transparent local governance system",
      slogan2_item3:
        "Equal and effective participation in decision-making processes",
      slogan2_item4: "Increased sense of fellowship and belonging",

      slogan3: "Grow city economy with network effects",
      slogan3_description:
        "Stimulate the energy, creativity, diversity, and economy of the city.",
      slogan3_item: "Contribution to the city economy and employment",
      slogan3_item2:
        "Supporting the city's creative potential and entrepreneurship",
      slogan3_item3: "New sources of income",
      slogan3_item4: "Open innovation",

      slogan4: "Facilitate access to social services and cooperation",
      slogan4_description:
        "Build a prosperous and sharing city by meeting social needs equally and inclusively.",
      slogan4_item:
        "An application that everyone can easily access and use safely",
      slogan4_item2: "Easy, practical, convenient access to municipal services",
      slogan4_item3: "Social assistance and community building",
      slogan4_item4: "Disaster and crisis management",

      slogan5:
        "Grow the local economy by supporting the entrepreneurial ecosystem",
      slogan5_description:
        "Leverage start-ups and SMEs by offering an easy-to-access ecosystem.",
      slogan5_item: "Public-private partnerships (PPP)",
      slogan5_item2:
        "Reputable, reliable traffic provided by the city brand and technology",
      slogan5_item3: `Investor environments (B2B matchmaking, "Kickstarter", crowdsourcing)`,
      slogan5_item4: "Branding and reference",
      slogan5_item5: "Opportunity to compete against big players",
    },
  },
  Technology: {
    section1: {
      slogan: {
        bold: "KOBIL Secure ",
        light: "Super App Technology",
      },
      description: {
        light: "",
        bold: "Platform Istanbul",
        light2:
          "enables more than 60 million users to make secure financial transactions in Turkey and Europe. It provides its customers with a high-security, uninterrupted, easy-to-use platform business model with the new generation",
        bold2: "Super Application Technology",
        light3: "by using ",
        bold3: "KOBIL's",
        light4: "technological infrastructure.",
      },
    },
    section2: {
      identity_slogan: "Empowers secure transactions.",
      identity_description:
        "Reassure your customers through our patented product offering a high-security digital identity solution.",

      pay_slogan: "Empowers easy payment transactions",
      pay_description:
        "It facilitates even the most complex transactions with its powerful and secure payment system.",

      connect_slogan: "Empowers meaningful interactions",
      connect_description:
        "Provide simple, effective, responsive, and secure communication.",
    },
    section3: {
      slogan: "KOBIL Security Technology",
      description:
        "Our multi-layered and patented technology provides the highest level of privacy and security. It is always in compliance with all relevant norms such as KVKK, GDPR.",
    },
  },
  Footer: {
    title: "Contact Us",

    address: {
      line1: "Sanayi District, Teknopark Avenue, No: 1/7C",
      line2: "Yeditepe University Teknoloji Campus, Floor: 3,",
      line3: " Apartment No: 303, 34906 Pendik/İstanbul",
    },

    email: "info@platformistanbul.ist",

    form_title: "Stay informed of our latest projects:",
    form_input_placeholder1: "Name - Surname",
    form_input_placeholder2: "E-mail",
    form_permission: {
      text: "I consent to transfer my data to domestic and international third parties per the Clarification Text, and I accept that I have been duly informed on this matter.",
      underline_text: "View Clarification Text.",
    },
    form_permission2: {
      text: "I accept to recieve commercial electronic messages to be sent to me via e-mail for promotion, information, advertisement, and marketing purposes regarding Platform Istanbul Teknoloji A.Ş products and services and my personal data to be processed for this purpose within the scope of the Clarification Text.",
      underline_text: "View Clarification Text.",
    },
    form_button: "Subscribe",
    footer_bottom_text_part1: "Platform Istanbul is a",
    footer_kobil: "KOBIL GROUP",
    footer_bottom_text_part2: "Company.",
    footer_bottom_text2: "©2021 Platform Istanbul",
    footer_bottom_text3: "Privacy Policy",
  },
  ClarificationText: {
    title: "CLARIFICATION TEXT REGARDING PROCESSING OF PERSONAL DATA",
    subtitle1: "Data Controller",
    subtitle2: "Platform İstanbul Teknoloji Anonim Şirketi",
    address:
      "Sanayi Mah. Teknopark Bulv. 1/7C, Yeditepe Üniversitesi Teknoloji Üssü, K:3, İç Kapı:303, İstanbul",
    content1: `As Platform İstanbul Teknoloji Anonim Şirketi (“PİTAŞ”), in the capacity of Data
      Controller, in line with the Law on the Protection of Personal Data No. 6698 (“LPPD”) and
      related legislation and legal regulations; we would like to inform you about the processing,
      storage and transfer of your personal data. Your personal data that you share with our
      Company can be processed in accordance with LPPD, in connection with our activity and
      service purposes and in a measured manner, transferred to third parties domestic and abroad,
      stored, used for profiling and classified.`,
    content2_title: `What are the Purposes of Processing, Legal Reasons and Collection Methods of Your
      Personal Data?`,
    content2_text1: `From time to time, your personal data, including sensitive personal data, shall be collected
      and used by our Company if it is necessary to process your personal data for our Company to
      fulfill its legal obligations, in order that data processing is mandatory for the establishment,
      protection or exercise of a right, for the establishment and performance of the commercial
      contractual relationship between you and our Company, for the legitimate purposes of our
      Company or, if you have given your consent in this regard, for sending commercial electronic
      messages for the purpose of promoting and marketing the products and services of our
      Company.`,
    content2_text2: `Your personal data collected through these methods can be processed and transferred for the
      purposes specified in the articles in the Clarification Text within the scope of the personal
      data processing conditions and purposes specified in the 5th and 6th articles of LPPD.`,
    content3_title: `To Whom Your Personal Data Can Be Transferred and For What Purposes?`,
    content3_text1: `Your personal data can be transferred to company officials, business partners and service
    providers, our affiliates and legally authorized public institutions and private individuals for
    our Company to fulfill its legal obligations, in order that data processing is mandatory for the
    establishment, protection or exercise of a right, for the establishment and performance of the
    commercial contractual relationship between you and our Company, for the legitimate
    purposes of our Company or, if you have given your consent in this regard, for the purpose of
    sending commercial electronic messages for the purpose of promoting and marketing the
    products and services of our Company. By following the rules specified in Article 8 of LPPD
    and taking the necessary technical and administrative measures, only to the extent necessary
    for the realization of the relevant purpose; can be transferred. Your personal data can be
    recorded in our programs and / or systems provided / made available to us by our
    infrastructure suppliers in the field of technology.`,
    content4_title: `What Are Your Rights as a Relevant Person?`,
    content4_text1: `Regarding your personal data within the scope of LPPD and related legislation, you have the
    rights;`,
    content4_list_item1:
      "To learn whether your personal data is processed or not,",
    content4_list_item2:
      "If your personal data has been processed, to request information about it,",
    content4_list_item3: `To learn the purpose of processing personal data and whether they are used in
    accordance with the purpose or not,`,
    content4_list_item4: `To know the third parties to whom your personal data is transferred, domestic or
      abroad,`,
    content4_list_item5: `To request correction of your personal data if it is processed incomplete or incorrectly,`,
    content4_list_item6: `To request the deletion or destruction of your personal data within the framework of
      the conditions stipulated in LPPD legislation,`,
    content4_list_item7: `When you request the deletion or destruction of your personal data by correcting
    incomplete or incorrect data, to request that this situation be notified to the third
    parties to whom we have transferred your personal data,`,
    content4_list_item8: `To object to the emergence of a result against the person himself by analyzing the
    processed data exclusively through automated systems and`,
    content4_list_item9: `If you suffer by damage due to unlawful processing of personal data, to request the
    removal of this damage.`,
    content5_title: `How Can You Exercise Your Rights?`,
    content5_text1: `If you wish, you can send your applications and requests regarding your personal data,
    through the application form on our website to PITAŞ;`,
    content5_list_item1_part1: `by sending an e-mail to`,
    content5_list_item1_part2: `with your secure electronic
    signature or mobile signature, provided that your identity confirmation is made,`,
    info_mail: "info@platformistanbul.ist",
    content5_list_item2: `by sending them to the address of Sanayi Mah. Teknopark Bulv. 1/7C, Yeditepe
    Üniversitesi Teknoloji Üssü, K:3, İç Kapı:303, İstanbul with the wet signed and
    documents certifying your identity.`,
    content5_text2: `Pursuant to the Notice on the Procedures and Principles of Application to the Data Controller,
    it is obligatory to include the name, surname, signature if the application is written, T.C
    identification number, (in case of the applicant is a foreigner, nationality, passport number or
    identification number, if any), notification address or workplace address, e-mail address for
    notification, if any, telephone number and fax number and information about the subject of
    the request in the application of the Relevant Person.`,
    content5_text3: `The Relevant Person must clearly and comprehensibly state the issue requested in the
    application which includes the explanations to be made in order to exercise the above-
    mentioned rights and the explanations regarding the right he/she requests to exercise.
    Information and documents related to the application must be attached to the application.`,
    content5_text4: `While the subject of the request should be related to the person of the applicant, if acting on
    behalf of someone else, the applicant must be specifically authorized in this regard and this
    authority must be documented (power of attorney). In addition, the application must contain
    identity and address information, and documents confirming identity must be attached to the application.`,
    content5_text5: `Requests made by unauthorized third parties on behalf of someone else will not be
    considered.`,
    content5_text6: `If your application for these purposes requires an additional cost, you may have to pay the fee
    in the tariff to be determined by the Personal Data Protection Board.`,
    content6_title: `How Long Will Your Requests Regarding the Processing of Your Personal Data Be
    Answered?`,
    content6_text1: `Your claims regarding your personal data are evaluated and answered within 30 (thirty) days
    at the latest from the date they reach us. In case your application is evaluated negatively, the
    reasons for motivated refusal are sent to the address you specified in the application, by one of
    the methods selected in the Relevant Person Application Form, especially by e-mail or via courier.`,
  },

  Privacy_policy: {
    title: "PRIVACY POLICY",

    entrance: [
      {
        text: "Our company has adopted the principle of protecting the privacy of visitors to our website and wanted to inform you about the use and protection of your information. If you do not agree with this policy, we recommend that you do not use or visit our website.",
      },
      {
        text: "Our company takes all necessary precautions and measures to comply with the provisions of the Law on the Protection of Personal Data No. 6698 (“LPPD”) and, as appropriate, the provisions of the European Union Data Protection Regulation (“GDPR”) in order to process personal data in accordance with the law. However, despite these measures, the Internet is an open system and the Company does not undertake that unauthorized third parties will not be able to exceed the technical measures taken by the Company.",
      },
      {
        text: "Possible changes to this policy will be posted on this page. In this framework, you will be able to receive up to date information on how this information is used and what information is stored.",
      },
      {
        text: "Our website may contain links to other websites over which are not under our control. Our company is not responsible for the privacy rules or other practices of other websites. We recommend that you review the privacy rules of such websites and examine how they collect, use and share your information. In addition, our Company does not make any statements or commitments about the accuracy of the contents of other websites.",
      },
      {
        text: "The content of our website has been created with the utmost accuracy. However, we cannot guarantee the accuracy, completeness and timeliness of the content. As a service provider, we are responsible for the regulatory compliance of our content on websites.",
      },
    ],

    subtitle1: "PERSONAL DATA",
    subtitle2: "COOKIES",
    subtitle3: "Internet Analysis With Google Analytics:",
    subtitle4: "LinkedIn:",
    subtitle5: "YouTube:",
    subtitle6: "Data Subject Rights:",

    personal: [
      {
        text: "Unless you voluntarily choose to share personal data with our Company, our Company does not collect any personal data about you through our website without your consent, except for those that do not require your explicit consent within the scope of applicable legislation for the protection of your personal data.",
      },
      {
        text: "By using our website or by submitting your personal data, you consent to the collection, use, processing and sharing of your personal data with us in accordance with this policy, the clarification text on our website and the applicable legislation. If you do not consent to the collection, use, processing and sharing of your personal data with us as specified in this policy and the clarification text on our website, you are kindly requested not to use our website and Contact Us forms.",
      },
      {
        text: "You may have shared your identity and contact information with us through the contact form named 'Contact us' on our website or through our call center numbers on the relevant tab. We will use your personal data to respond to your request.",
      },
      {
        text: "If the processing is necessary to meet your current request, the processing and use of your data will be carried out in accordance with the provisions of LPPD and in the interests of our company. For more information, you can review the clarification text on our website for the processing of personal data.",
      },
    ],

    cookies: [
      {
        text: "When you access our website, we may automatically collect non-personal data (for example, the type of Internet browser and operating system used, the domain name of the website you came from, the number of visits, the average time spent on the site, the pages viewed). We may use this data and share it with our worldwide business partners and shareholders to improve the content of our website.",
      },
      {
        text: "Cookies are small units of data that are required to use our website and are temporarily stored on your hard disk by your browser. A cookie usually contains a unique identifier which is a randomly generated number and is stored on your device. Some of these identifiers expire when your website session ends, while others may remain on your device for longer periods of time. Technically necessary cookies will be used automatically. Other cookies (or similar technologies) will only be applied with your preapproval. You can prevent the installation of cookies from the settings in your browser, but as a result you may not be able to use some functions of our website.",
      },
    ],

    internetAnalysis: [
      {
        text: "Our website may use Google Analytics, the web analysis service of Google Inc. (“Google”). Google Analytics uses text files, namely, cookies that can be stored on your computer and allow website usage analysis. Cookies (including your shortened IP address) and information about the use of the generated website are transferred to Google servers in the United States and stored there. Google uses this information to evaluate the use of the website, to prepare reports showing website activities for the website provider, and to provide website usage and other internet-related services. However, Google may transfer this information to third parties if there is a legal obligation or if this data is processed by third parties at Google's request. Under no circumstances will Google combine your IP address with other Google-sourced data.",
      },
      {
        text: "As the websites provider, we point out that we are not aware of the content of the transferred data and its use by Google. More information could be found in Google's Privacy Policy.",
      },
    ],

    linkedin:
      "Our website may use functions of the LinkedIn network. Provider is LinkedIn Corporation, headquartered in 2029 Stierlin Court, Mountain View, CA 94043, United States. Whenever accessing our websites where the LinkedIn function is used, there will be a connection to LinkedIn servers. LinkedIn will be aware that you have visited our websites with your IP address. When you click LinkedIn's 'Recommend-Button' and log in to LinkedIn, your visit to LinkedIn's website will be unique to you and your user account. As the provider of the websites, we state that we are not aware of the content of the data transferred and its use by LinkedIn. More information could be found in LinkedIn's Privacy Policy.",

    youtube:
      "Our website may use Google plugins for the YouTube site. The provider is YouTube, LLC, headquartered at 901 Cherry Ave., San Bruno, CA 94066, United States. If you are using one of our websites with in-built YouTube plugins, there will be an established connection to the YouTube servers. In this way, the YouTube servers will be aware that you have visited our website. When you log into your YouTube account, YouTube will set your browsing behavior to your personal profile. As the provider of the websites, we state that we are not aware of the content of the transmitted data and its use by Youtube. More information could be found in Youtube's Privacy Policy.",

    dataSubjects: [
      {
        text: "You can submit your requests in Article 11 of LPPD, provided that your identity confirmation is made, in accordance with the provisions of the 'Notification on Application Procedures and Principles to the Data Controller' via the application form on our website, if you wish you could also send it to Sanayi Mah. Teknopark Bulv. 1/7C-303, Pendik, İstanbul in person or with your secure electronic signature or mobile signature to info@platformistanbul.ist. e-mail address.",
      },
      {
        text: "Our company will finalize your application requests in accordance with Article 13 of LPPD, according to the nature of the request and within 30 (thirty) days at the latest.",
      },
    ],
  },
};
