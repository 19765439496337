import { Box, Button } from "@mui/material";
import React from "react";
import CustomTypography from "../../components/shared/CustomTypography";
import "./style.css";
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";
import IsOutsideClick from "../../helpers/isOutsideSlick";
import { useTranslation } from "react-i18next";
import useServerLanguage from "../../hooks/useServerLanguage";

const ClarificationText = ({ onClose: closeHandler }) => {
  const { i18n } = useTranslation();
  const language = useServerLanguage("clarify");

  console.log(language);
  return (
    <Box className="clarification-background">
      <IsOutsideClick handler={closeHandler}>
        <Box className="clarification-container">
          <CustomTypography
            variant="text2"
            color="black"
            fontSize={"1.563rem"}
            fontWeight="bold"
          >
            {language?.title || ""}
          </CustomTypography>
          <br />
          <CustomTypography variant="informal">
            <strong>
              {language?.subtitle_one || ""} : {language?.subtitle_two || ""}
            </strong>
            <br />
            {language?.address || ""}
          </CustomTypography>
          <br />
          <br />
          <CustomTypography variant="informal">
            {language?.content_one || ""}
          </CustomTypography>
          <Box className="clarification-content-container">
            <div>
              <CustomTypography
                component="h3"
                variant="informal"
                className="cc-title"
                marginTop={"22px"}
              >
                {language?.content_two_title || ""}
              </CustomTypography>
              <CustomTypography variant="informal">
                {language?.content_two_text_one || ""}
                <br />
                <br />
                {language?.content_two_text_two || ""}
              </CustomTypography>
            </div>
            <div>
              <CustomTypography
                component="h3"
                variant="informal"
                className="cc-title"
              >
                {language?.content_three_text_one || ""}
              </CustomTypography>
              <CustomTypography variant="informal">
                {language?.content_three_text_two || ""}
              </CustomTypography>
            </div>
            <div>
              <CustomTypography
                component="h3"
                variant="informal"
                className="cc-title"
              >
                {language?.content_four_title || ""}
              </CustomTypography>
              <CustomTypography variant="informal">
                {language?.content_four_text_one || ""}
              </CustomTypography>
              <ul>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_one || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_two || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_three || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_four || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_five || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_six || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_seven || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_eight || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_four_list_item_nine || ""}
                  </CustomTypography>
                </li>
              </ul>
              <br />
              {i18n.language !== "en" ? " haklarına sahipsiniz." : ""}
            </div>
            <div>
              <CustomTypography
                component="h3"
                variant="informal"
                className="cc-title"
              >
                {language?.content_five_title || ""}
              </CustomTypography>
              <CustomTypography variant="informal">
                {language?.content_five_text_one || ""}
              </CustomTypography>
              <ul>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_five_list_item_one_part_one || ""}{" "}
                    <a href="mailto:info@platformistanbul.ist">
                      info@platformistanbul.ist
                    </a>{" "}
                    {language?.content_five_list_item_one_part_two || ""}
                  </CustomTypography>
                </li>
                <li>
                  <CustomTypography variant="informal">
                    {language?.content_five_list_item_two || ""}
                  </CustomTypography>
                </li>
              </ul>
              <br />
              {i18n.language !== "en" ? " PİTAŞ’a iletebilirsiniz." : ""}
            </div>
            <div>
              <CustomTypography variant="informal">
                {language?.content_five_text_two || ""}
              </CustomTypography>
            </div>
            <div>
              <CustomTypography variant="informal">
                {language?.content_five_text_three || ""}
              </CustomTypography>
            </div>
            <div>
              <CustomTypography variant="informal">
                {language?.content_five_text_four || ""}
              </CustomTypography>
            </div>
            <div>
              <CustomTypography variant="informal">
                {language?.content_five_text_five || ""}
              </CustomTypography>
            </div>
            <div>
              <CustomTypography variant="informal">
                {language?.content_five_text_six || ""}
              </CustomTypography>
            </div>
            <div>
              <CustomTypography
                component="h3"
                variant="informal"
                className="cc-title"
              >
                {language?.content_six_title || ""}
              </CustomTypography>
              <CustomTypography variant="informal">
                {language?.content_six_text_one || ""}
              </CustomTypography>
            </div>
          </Box>
        </Box>
      </IsOutsideClick>
      <Button className="clarification-btn" onClick={closeHandler}>
        <CloseIcon />
      </Button>
    </Box>
  );
};

export default ClarificationText;
