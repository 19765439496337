import React from "react";
import "./style.css";

const CustomButton = (props) => {
  const { variant, children, ...rest } = props;

  const addClassByVariant = {
    textButton: "custom-btn--text-btn",
  };
  return (
    <button className={`custom-btn ${addClassByVariant[variant]}`} {...rest}>
      {children}
    </button>
  );
};

export default CustomButton;
